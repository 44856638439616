import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLastVisitedPage } from '../redux/slices/navigationSlice'; // Adjust the path based on your project structure

export const CaptureLastVisitedPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch the last visited page to the Redux store
    dispatch(setLastVisitedPage(location.pathname));
  }, [location, dispatch]);

  return null; // This component doesn't render anything visible
};