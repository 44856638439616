import './index.css'
import { RoutingOldSite } from './pages/Routing';
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {store, xpersistor} from '../src/redux/store'

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
  <PersistGate loading={<div>Loading...</div>} persistor={xpersistor}>
      <RoutingOldSite />
  </PersistGate>
  </Provider>
    ,
    document.getElementById('root')
  );