import { combineReducers, createStore, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {thunk} from 'redux-thunk'; // Import redux-thunk middleware
import authReducer, { doLogout, doLogin } from './slices/authSlice';
import navigationReducer from './slices/navigationSlice';
//import { decodeToken, isTokenExpired } from '../components/auth';


//WRITE MIDDLEWARE FOR TOK REFRESH.... instead handled in interceptor
// const tokenMiddleware = store => next => action => {
//   const result = next(action);
//   const state = store.getState();

//   const token = state.auth.accessToken;
//   const tokenInStorage = localStorage.getItem('pwauthtok');

//   if (state.auth.isLoggedIn && isTokenExpired(token)) {
//     store.dispatch(doLogout());
//   } else if (!state.auth.isLoggedIn && tokenInStorage && !isTokenExpired(tokenInStorage)) {
//     store.dispatch(doLogin({ idToken: { getJwtToken: () => tokenInStorage, payload: decodeToken(tokenInStorage) } }));
//   }

//   return result;
// };

const rootReducer = combineReducers({
  auth: authReducer,
  navigation: navigationReducer,  // For handling the last visited page
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Apply both tokenMiddleware and thunk
export const store = createStore(
  persistedReducer,
  //applyMiddleware(tokenMiddleware, thunk) // Apply thunk middleware here
  applyMiddleware(thunk) // Apply thunk middleware here
);

export const xpersistor = persistStore(store);
