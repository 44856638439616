import * as React from "react";
import face from "../files/img/paulface.jpg";
import Tooltip from "@mui/material/Tooltip";
import EmailIcon from "@mui/icons-material/Email";

//note that tyypes.d.ts includes the extension to prevent compile errors (eg .png)
import quizImg from "../files/img/quiz_219x154.png";
import circusImg from "../files/img/circus_219x154.png";
import cabinImg from "../files/img/cabin_219x154.png";
import pollutionImg from "../files/img/canada_pollution_219x158.png";
import rscoringImg from "../files/img/r.scoring.git_219x154.png";
import snaImg from "../files/img/sna_219x154.jpg";
import eveImg from "../files/img/eve_market_219x154.png";
import screepsImg from "../files/img/screeps_219x154.png";
import opensourceImg from "../files/img/opensource_219x154.png";
import paulImg from "../files/img/paul_219x154.png";
import { Link } from "react-router-dom";
import "./styles/home.scss";
import { isLambda } from "../shared/initWith";

export function Home() {
  return (
    <>
    <div className="App" style={{height:'100%'}}>
      <header className="App-header">
        <img src={face} alt="face-img" className="avatar" />
      </header>
      <section id="programming">
        <div className="row feature-section">
          <div className="col-lg-12 text-center">
            <h2 className="section-header">Programming</h2>
            <h5>(Click an image for more info)</h5>
            <p />
          </div>
          {/*-----------------------------------------------------------------------------------------------------*/}
          <div className="portfolio-item">
            <a
              href="quiz"
              className="portfolio-link"
              data-toggle="modal" /*target="_blank" rel="noreferrer"*/
            >
              <Tooltip title="Click to see more" placement="top" arrow>
                <img
                  src={quizImg}
                  className="img-responsive"
                  alt="link to serverless cloudformation design"
                />
              </Tooltip>
            </a>
            <div>
              A Material UI programming quiz example, with questions about
              Dynamo DB.
            </div>
          </div>
          {/*-----------------------------------------------------------------------------------------------------*/}
          <div className="portfolio-item">
            <a
              href="opensource"
              className="portfolio-link"
              data-toggle="modal" /*target="_blank" rel="noreferrer"*/
            >
              <Tooltip title="Click to see more" placement="top" arrow>
                <img
                  src={opensourceImg}
                  className="img-responsive"
                  alt="link to opensource contribution page"
                />
              </Tooltip>
            </a>
            <div>
              A brief list of some of my opensource programming contributions
            </div>
          </div>
          {/*-----------------------------------------------------------------------------------------------------*/}
          <div className="portfolio-item">
            <a
              href="paulweb"
              className="portfolio-link"
              data-toggle="modal"
            >
              <Tooltip title="Click to see more" placement="top" arrow>
                <img
                  src={paulImg}
                  className="img-responsive"
                  alt="link to serverless design"
                />
              </Tooltip>
            </a>
            <div>This website: completely serverless, deployable via CloudFormation templates, nearly free to run.</div>
          </div>
          {/*-----------------------------------------------------------------------------------------------------*/}
          <div className="portfolio-item">
            <a
              href="screeps"
              className="portfolio-link"
              data-toggle="modal"
            >
              <Tooltip title="Click to see more" placement="top" arrow>
                <img
                  src={screepsImg}
                  className="img-responsive"
                  alt="link to serverless cloudformation design"
                />
              </Tooltip>
            </a>
            <div>An open-source Screeps AI that uses the OS model. It has a couple of novel approaches to solve common screeps problems.</div>
          </div>
                    {/*-----------------------------------------------------------------------------------------------------*/}
                    <div className="portfolio-item">
            <a
              href="resumeai"
              className="portfolio-link"
              data-toggle="modal"
            >
              <Tooltip title="Click to see more" placement="top" arrow>
                <img
                  src={cabinImg}
                  className="img-responsive"
                  alt="link to demo AI resume analyzer"
                />
              </Tooltip>
            </a>
            <div>A simple AI resume analyzer using multiple Machine Learning techniques</div>
          </div>
        </div>
      </section>
      {
        ////////////////////////////////////////////////////////////////////////////////////
      }
      <section id="dataanalytics">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-header">Data Analytics</h2>
            <h5>(Click an image for more info)</h5>
            <p />
          </div>
        </div>
        <div className="row feature-section">
          {" "}
          <div className="portfolio-item">
            <a
              href="https://paul-weiler-r-viz.shinyapps.io/canada_pollution/"
              className="portfolio-link"
              data-toggle="modal"
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip title="Click to see more" placement="top" arrow>
                <img
                  src={pollutionImg}
                  className="img-responsive"
                  alt="link to R canadian pollution by source"
                />
              </Tooltip>
            </a>
            <div>
              A Shiny App designed to quickly and easily compare pollution by
              industry.
            </div>
          </div>
          <div className="portfolio-item">
            <a
              href="https://github.com/yzpaul/r-psych-scoring"
              className="portfolio-link"
              data-toggle="modal"
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip title="Click to see more" placement="top" arrow>
                <img
                  src={rscoringImg}
                  className="img-responsive"
                  alt="link to Rscoring Git repo"
                />
              </Tooltip>
            </a>
            <div>
              R functions I wrote to automate tasks I did frequently as a grad
              student. This is mostly scoring psychology scales and generating
              APA format for publications.
            </div>
          </div>
          <div className="portfolio-item">
            <Tooltip title="Click to see more" placement="top" arrow>
              <Link to="/htmlpages/sna">
                <img src={snaImg} className="img-responsive" alt="SNA graph" />
              </Link>
            </Tooltip>
            <div>
              This visualization was created for a lecture I gave on creating
              social networks in R. I used data I collected from a research
              experiment on Minecraft.
            </div>
          </div>
          <div className="portfolio-item">
          {/* href="https://paul-weiler-r-viz.shinyapps.io/eve_market_analysis/" */}
            <Tooltip title="Click to see more" placement="top" arrow>
            <Link to="/htmlpages/eve">
                <img src={eveImg} className="img-responsive" alt="eve walkthrough" />
              </Link>
            </Tooltip>
            <div>
              An R/Shiny App designed to predict the prices of items in the MMO Eve
              using ARIMA forecasting. A walkthrough of how I did it (with source code)
            </div>
          </div>
          <div className="portfolio-item">
            <Tooltip title="Click to see more" placement="top" arrow>
              <Link to="/htmlpages/ggplot">
                <img
                  src={circusImg}
                  className="img-responsive"
                  alt="ggplot walkthrough"
                />
              </Link>
            </Tooltip>
            <div>
              This ggplot example was inspired by a friend who asked for
              graphing help. It covers data formatting and a basic ggplot usage
              example in R.
            </div>
          </div>
          <div className="portfolio-item">
            <Tooltip title="Click to see more" placement="top" arrow>
              <Link to="/mortgage">
                <img
                  src={cabinImg}
                  className="img-responsive"
                  alt="predicting mortgages"
                />
              </Link>
            </Tooltip>
            <div>
              This uses machine learning and technical analysis to predict the mortgage rate for the next week.
            </div>
          </div>
        </div>
      </section>
      <br />
      <div className="col-lg-12 text-center">
      <h3>Reach Out to me!</h3>
      Comments, questions, job offers? Shoot me an email at: yzpaul@gmail.com or click the icon:
      <EmailIcon
        onClick={() => window.open("mailto:yzpaul@gmail.com", "_blank")}
      />
      <br />
      Location: Atlanta, Georgia
      <br />
      </div>
      </div>
    </>
  );
}
