import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NavigationState {
  lastVisitedPage: string;
}

const initialState: NavigationState = {
  lastVisitedPage: '/', // Default to home page
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setLastVisitedPage: (state, action: PayloadAction<string>) => {
      if(!['/login','/'].includes(action.payload)){
      console.log(`navSlice:`,action.payload)
      state.lastVisitedPage = action.payload;
      }
    },
  },
});

export const { setLastVisitedPage } = navigationSlice.actions;
export default navigationSlice.reducer;
