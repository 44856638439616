import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { CognitoUserAttributes } from "../../login/interfaces";
import { getUser } from "../../components/auth"; // Assuming you have helper functions here

export interface AuthState {
  userData: CognitoUserAttributes | null;
  accessToken:string;
  isLoggedIn: boolean;
  now: number;
}

const initialState: AuthState = {
  accessToken:'',
  userData: null,
  isLoggedIn: false,
  now: 0,
};

// Thunk to refresh the session using Cognito
export const refreshSession = createAsyncThunk<
  RefreshSessionResponse, // Return type on success
  void,                   // Argument type for the thunk (no argument)
  { rejectValue: RefreshSessionError } // Rejected value type
>(
  'auth/refreshSession',
  async (_, { rejectWithValue }) => {
    try {
      const cognitoUser = getUser(); // Fetch the Cognito user
      if (!cognitoUser) throw new Error("No current user");

      return new Promise<RefreshSessionResponse>((resolve, reject) => {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.error('Error getting session:', err);
            return reject(err);
          }

          if (session.isValid()) {
            // Access token is still valid, return session details
            resolve({
              accessToken: session.getAccessToken().getJwtToken(),
              refreshToken: session.getRefreshToken().getToken(),
              userData: session.getIdToken().payload as CognitoUserAttributes,
            });
          } else {
            // Access token expired, refresh the session
            cognitoUser.refreshSession(session.getRefreshToken(), (err, newSession) => {
              if (err) {
                console.error('Error refreshing session:', err);
                return reject(err);
              }
              resolve({
                accessToken: newSession.getAccessToken().getJwtToken(),
                refreshToken: newSession.getRefreshToken().getToken(),
                userData: newSession.getIdToken().payload as CognitoUserAttributes,
              });
            });
          }
        });
      });
    } catch (error) {
      return rejectWithValue({ message: error.message });
    }
  }
);

export interface RefreshSessionResponse {
  accessToken: string;
  refreshToken: string;
  userData: CognitoUserAttributes;
}

export interface RefreshSessionError {
  message: string;
}

// Redux Slice
export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    // Cognito login handler
    doLogin: (state, action: PayloadAction<{ idToken: any,accessToken:any,refreshToken:any }>) => {
      const { idToken, accessToken, refreshToken } = action.payload;

      const tokenPayload = idToken.payload as CognitoUserAttributes;
      state.userData = tokenPayload;
      state.accessToken = idToken.getJwtToken();
        state.isLoggedIn = true;
        state.now = Date.now();

    // TODO: https://stackoverflow.com/questions/53345916/api-gateway-authorizer-accepts-id-token-but-not-access-token
    //you must specify scope at method level in order to use accessToken. OTHERWISE DEFAULT SHOULD USE IDENTITY TOKEN        

        // console.log(`USER AUTHED`);
        // console.log('Access Token:', accessToken);
        // console.log('ID Token:', idToken.getJwtToken());
        // console.log('Refresh Token:', refreshToken);
    },

    // Cognito logout handler
    doLogout: (state) => {
      const cognitoUser = getUser();
      if (cognitoUser) {
        cognitoUser.signOut(); // Logout user via Cognito
        state.isLoggedIn = false;
        state.accessToken=''
        state.userData = null;
        state.now = 0;
        console.log('User has been logged out.');
      } else {
        console.log('No user is currently signed in.');
      }
    },
  },

  // Handle extraReducers for async thunk
  extraReducers: (builder) => {
    builder.addCase(refreshSession.fulfilled, (state, action) => {
      state.userData = action.payload.userData;
      state.isLoggedIn = true;
    });

    builder.addCase(refreshSession.rejected, (state, action) => {
      console.error('Refresh session failed:', action.payload?.message);
      state.isLoggedIn = false;
      state.userData = null;
    });
  },
});

// Export actions and reducer
export const { doLogin, doLogout } = authSlice.actions;
export default authSlice.reducer;